$loading-gray: #f6f7f8;

@-webkit-keyframes loadingBackgroundAnimation {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.loading-label {
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: loadingBackgroundAnimation;
  -webkit-animation-timing-function: linear;
  background: $loading-gray;
  background-image: -webkit-gradient(
    linear,
    left center,
    right center,
    from($loading-gray),
    color-stop(0.2, #edeef1),
    color-stop(0.4, $loading-gray),
    to($loading-gray)
  );
  background-image: -webkit-linear-gradient(
    left,
    $loading-gray 0%,
    #edeef1 20%,
    $loading-gray 40%,
    $loading-gray 100%
  );
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}
