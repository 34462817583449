.button {
  background: rgba(0, 0, 0, 0.3);
  padding: 12px 24px;
  font-weight: bold;
  border: 1px solid #d9dadb;
}

.button:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
  border-color: initial;
}
