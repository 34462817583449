@import "../../../styles/variables";

.imgFooter {
  height: 300px;
  width: 100%;
  background-size: cover;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }

  @media (max-width: 768px) {
    height: 400px;
  }
}

.mobilecta {
  @media (max-width: 768px) {
    text-align: center;
    margin-top: 16px;
  }
}

.white {
  color: white !important;
}

.inputEmail {
  border: 0;
  border-radius: 0;

  &::placeholder {
    font-size: 14px;
    color: $gray-super-light;
  }
}

.custom-cta {
  background: rgba(153, 153, 153, 0.2);
  padding: 9px 24px;
}
