.exhibitions {
  /* background-image: url("https://pintar-al-oleo.com/wp-content/uploads/2013/05/pink-harmony-shabby-chic-igor-levashov.jpg"); */
  width: 100%;
  height: 205px;
  background-position: center center;
  background-size: cover;
  margin: 0 auto 24px;
  position: relative;
}
@media (max-width: 480px) {
  .exhibitions {
    width: 100%;
  }
}

.exhibitions::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  /* background-color: rgba(0, 0, 0, 0.6); */
  background: linear-gradient(to bottom, rgba(127, 127, 127, 0.2), black 120%);
}

.exhibitionInfo {
  position: relative;

  padding: 16px;
  height: 100%;
  z-index: 1;
}

.exhibitionInfo h4 {
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.exhibitionInfo p {
  line-height: 18px;
  letter-spacing: 0.17px;
}

.exhibitionInfo span {
  line-height: 17px;
  letter-spacing: 0.19px;
  opacity: 0.54;
  color: #ffffff;
}

.bottom {
  position: absolute;
  bottom: 16px;
}

.lh17px {
  margin-bottom: 5px !important;
}
