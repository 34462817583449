.banner {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 70px;
}
.container {
  z-index: 1;
}
.banner .banner-text {
  z-index: 1;
  position: relative;
}

.white {
  color: white !important;
}

.bannerTopPadding {
  padding-top: 25px;
}

.bannerTopPadding p {
  padding-left: 3px;
}

@media (max-width: 767px) {
  .banner {
    margin-top: 118px;
  }
}
