.circle-image-container {
  padding: 8px;
  text-align: center;
  display: block;
  color: black !important;
}

.circle-image-container:hover {
  color: black;
}

.circles {
  border-radius: 50%;
}

.gallery-logo {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: #a4a4a4;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gallery-inicials {
  font-size: 50px;
  color: white;
  margin-bottom: 0;
}
