.like-cards {
  width: 100%;
  height: 180px;
  margin-bottom: 1rem;
}

.like-cards > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.big {
  height: 550px;
}

.aspect-ratio > img {
  max-width: 100%;
  max-height: 100%;
  width: unset;
  height: unset;
  object-fit: unset;
}
