.button-container {
  background-color: black;
  margin-bottom: auto;
  color: white;
  margin-top: auto;
}

.button {
  border: none;
  padding-top: 12px;
  padding-bottom: 12px;
}

.button:hover {
  background-color: black;
}

.android,
.apple {
  width: 163px !important;
  height: 55px;
  display: inline-block;
  margin: 5px 5px;
}

.apple .button {
  background: url("/images/btn-appstore.png") no-repeat;
  background-size: 163px 55px;
  width: 100%;
  height: 100%;
  text-indent: -99999px;
}

.android .button {
  background: url("/images/btn-googleplay.png") no-repeat;
  background-size: 163px 55px;
  width: 100%;
  height: 100%;
  text-indent: -99999px;
}
