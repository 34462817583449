.row {
  column-width: 300px;
  column-gap: 1em;
  -moz-column-width: 300px;
  -webkit-column-width: 300px;
  -moz-column-gap: 1em;
  -webkit-column-gap: 1em;

  @media screen and (max-width: 1440px) {
    column-width: 280px;
    -moz-column-width: 280px;
    -webkit-column-width: 280px;
  }

  @media screen and (max-width: 1200px) {
    column-width: 200px;
    -moz-column-width: 200px;
    -webkit-column-width: 200px;
  }

  @media screen and (max-width: 461px) {
    column-width: 150px;
    -moz-column-width: 150px;
    -webkit-column-width: 150px;
  }

  @media screen and (max-width: 361px) {
    column-width: 120px;
    -moz-column-width: 120px;
    -webkit-column-width: 120px;
  }
}

.card {
  display: inline-block;
  padding: 0.25rem;
  width: 100%;
}

.item {
  display: block;
}
